export default {
  order: 1,
  name: "LittleCab Management",
  icon: "date_range",
  links: [
    {
      name: "Applications",
      icon: "mdi-account-cash",
      to: "/littlecab/applications",
      enabled: true,
    },
    {
      name: "approvalRequests",
      icon: "mdi-playlist-edit",
      to: "/littlecab/approvalRequests",
      enabled: true,
    },
    {
      name: "adminApprovals",
      icon: "mdi-playlist-edit",
      to: "/littlecab/admin-approvals",
      enabled: true,
    },
  ],
  hasSideLinks: true,
  enabled: true,
};
