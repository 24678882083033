import AppDashboard from "@/packages/cassandra-base/app/layout/AppDashboard";
import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
import LittleCabApprovalCard from "./components/LittleCabApprovalCard.vue";
import LittleCabApprovalRequestList from "./components/LittleCabApprovalRequestList.vue";
import LittleCabApplicationList from "@/packages/cassandra-littlecab/components/LittleCabApplicationList.vue";
import LittleCabCard from "@/packages/cassandra-littlecab/components/LittleCabCard.vue";
import cabAdminsApprovalList from "./components/cabAdminsApprovalList.vue";

export default [
  {
    path: "/littlecab",
    component: AppDashboard,
    children: [
      {
        path: "",
        redirect: "/littlecab/applications",
      },
      {
        path: "applications",
        name: "cabApplications",
        components: {
          view: LittleCabApplicationList,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            {
              text: "LittleCab",
              disabled: true,
              href: "/littlecab/applications",
            },
            {
              text: "Applications",
              disabled: false,
              href: "/littlecab/applications",
            },
          ],
          isApprover: false,
        },
      },
      {
        path: "card/:code?",
        name: "LittleCabCard",
        components: { view: LittleCabCard },
        meta: {
          middleware: Auth,
          breadcrumb: [
            { text: "LittleCab", disabled: true, href: "/little-cab/create" },
            {
              text: "Applications/Create",
              disabled: false,
              href: "/littlecab/applications/create",
            },
          ],
        },
      },
      {
        path: "approvalRequests",
        name: "approvalRequests",
        components: {
          view: LittleCabApprovalRequestList,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            {
              text: "CabApprovalRequests",
              disabled: false,
              href: "/littlecab/approvalRequests",
            },
          ],
          isApprover: false,
        },
      },
      {
        path: "card/:documentNo/:approverID",
        name: "LittleCabApprovalCard",
        components: {
          view: LittleCabApprovalCard,
        },
        meta: {
          middleware: Auth,
        },
      },
      {
        path: "admin-approvals",
        name: "adminApprovals",
        components: {
          view: cabAdminsApprovalList,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [
            {
              text: "cab-adminApprovals",
              disabled: false,
              href: "/littlecab/admin-approvals",
            },
          ],
          isApprover: false,
        },
      },
    ],
  },
];
