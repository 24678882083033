import call from "@kinetics254/cassandra-base/service/http";
import littleCabConstants from "./littleCabConstants";

export default {
  namespaced: true,
  state: {
    cabRequests: [],
    cabApprovalEntry: {},
    approvalApplications: [],
    cabAdmins: [],
    allCabRequests: [],
    cabRequestsDetails: {},
    dimensions: [],
    speedKeys: [],
    funds: [],
    currencies: [],
    approvalEntries: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    littleCabGetters: (state) => (val) => state[val],
    cabRequestsDetails: (state) => state.cabRequestsDetails,
  },
  actions: {
    getCabRequests: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", littleCabConstants.cabRequests(payload))
        .then((res) => {
          commit("MUTATE", {
            state: "cabRequests",
            data: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getCabApprovalApplications: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", littleCabConstants.approvalRequests(payload)).then((res) => {
        commit("loader/SET_LOADING", false, { root: true });
        commit("MUTATE", {
          state: "approvalApplications",
          data: res.data.data,
        });
      });
    },
    approve: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", littleCabConstants.approve, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("approvalentries");
          Event.$emit("ApiSuccess", "Cab request approved successfully");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    reject: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", littleCabConstants.reject, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("approvalentries");
          Event.$emit("ApiSuccess", "Cab request rejected successfully");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("error", error.response.data.message);
        });
    },

    createCabRequest: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", false, { root: true });
      call("post", littleCabConstants.create, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Cab request created successfully");
          const { requestedBy } = res.data.data.shft();
          console.log("requester", requestedBy);
          dispatch("getCabRequests", requestedBy);
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },

    updateCabRequest: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", false, { root: true });
      call("post", littleCabConstants.update, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Cab request updated successfully");
          const { requestedBy } = res.data.data[0];
          dispatch("getCabRequests", requestedBy);
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },

    accountForCabRequest: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", false, { root: true });
      call("post", littleCabConstants.account, payload)
        .then((re) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Cab request created successfully");
          Event.$emit("re-sync");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },

    requestApproval: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", false, { root: true });
      call("post", littleCabConstants.requestApproval, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiSuccess",
            "Cab request for Approval submitted successfully"
          );
          Event.$emit("re-sync");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },

    getCabRequestsDetails: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", littleCabConstants.cabRequestsDetails(payload))
        .then((res) => {
          commit("MUTATE", {
            state: "cabRequestsDetails",
            data: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getCabRequestAdmins: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", littleCabConstants.cabAdmins)
        .then((res) => {
          commit("MUTATE", { state: "cabAdmins", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getAllCabRequests: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", littleCabConstants.getAllCabRequests)
        .then((res) => {
          commit("MUTATE", { state: "allCabRequests", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    markCabRequestUsed({ commit, dispatch }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", littleCabConstants.markCabRequestUsed, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Cab request updated & marked used");
          dispatch("getAllCabRequests");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getfunds: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", littleCabConstants.funds)
        .then((res) => {
          commit("MUTATE", { state: "funds", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getdimensionSpeedKey: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", littleCabConstants.dimensionSpeedKey)
        .then((res) => {
          commit("MUTATE", { state: "speedKeys", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getDimensionValues: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", littleCabConstants.dimensions)
        .then((res) => {
          commit("MUTATE", { state: "dimensions", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    sendCancelRequest: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", littleCabConstants.cancelCabRequest, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "Request succesfully canceled");
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("re-sync");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    deleteCabRequest: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("delete", littleCabConstants.deleteRequest, data)
        .then(() => {
          Event.$emit("ApiSuccess", "Cab Request successfully deleted");
          Event.$emit("re-sync");
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getApprovalEntries({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", littleCabConstants.appEntries(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "approvalEntries", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
