export default {
  cabRequests: (employeeNo) =>
    `hrmis/littlecab/requests?employeeNo=${employeeNo}`,
  create: "/hrmis/littlecab/requests/store",
  update: "/hrmis/littlecab/requests/update",
  approvalRequests: (params) =>
    `approvals/approvalEntries?${formatParams(params)}`,
  approve: "approvals/approve",
  reject: "approvals/reject",
  cabRequestsDetails: (params) => `hrmis/littlecab/requests/${params}`,
  requestApproval: "hrmis/littlecab/requests/approval-request",
  account: "/hrmis/littlecab/requests/account",
  cabAdmins: `hrmis/littlecab/requests/setup/cab-admins`,
  markCabRequestUsed: `hrmis/littlecab/requests/mark-used`,
  getAllCabRequests: `hrmis/littlecab/requests/all`,
  deleteRequest: `hrmis/littlecab/requests`,
  cancelCabRequest: `hrmis/littlecab/requests/cancel-request`,
  funds: "hrmis/imprest/funds",
  dimensionSpeedKey: "hrmis/imprest/dimensionSpeedKey",
  dimensions: "hrmis/imprest/dimensions",
  currencies: "hrmis/imprest/currencies",
  appEntries: (payload) => `approvals/approvalEntries?documentNo=${payload}`,
};

function formatParams(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}
