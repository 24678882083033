<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title class="top-bar mb-2">
        <h3 class="primary--text text-lg-h5 mr-2">
          Cab Admins Approval Requests
        </h3>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="cabRequests"
          :sort-desc="[true, true]"
          :search="search"
          multi-sort
          class="elevation-1"
          ref="cabrequest_list"
          id="scollStyle"
        >
          <template v-slot:item.postingDate="{ item }">
            <span>{{ getDate(item.postingDate) }}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :color="getStatusColor(item.status)">{{
              item.status
            }}</v-chip>
          </template>
          <template v-slot:item.used="{ item }">
            <span>{{ item.used === true ? "Used" : "Not Used" }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <!-- mark as used -->
            <v-btn
              v-if="canMarkCabRequestUsed(item)"
              class="primary lighten-4 primary--text text--darken-4"
              elevation="0"
              @click="markUsedHandler(item)"
            >
              <v-icon class="mx-2">mdi-gesture-tap-button</v-icon>
              Mark used
            </v-btn>
            <!-- <v-chip
              v-else
              class="success lighten-4 primary--text text--darken-4"
              >No Action</v-chip
            > -->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" persistent max-width="80%">
      <v-card class="mx-auto" width="100%">
        <v-card-text>
          <div class="text-body-1">
            Sorry, you are not allowed to view this cab page. Please contact the
            system-admin/admin for more information.
          </div>
        </v-card-text>
        <v-card-actions>
          <div>Redirecting in {{ countDown }} seconds...</div>
          <v-spacer />
          <v-btn
            color="success"
            small
            class="text-body-1 text-capitalize"
            link
            to="/"
          >
            <v-icon class="mx-2">dashboard</v-icon>
            Go to Dashboard
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { helper } from "@kinetics254/cassandra-base/utilities";
export default {
  name: "CabAdminApprovalRequests",
  mixins: [],
  components: {},
  data: function () {
    return {
      search: "",
      dialog: false,
      countDown: 5,
      isCabAdmin: true,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      //dispatch to get cab admins
      v.$store.dispatch("littlecab/getCabRequestAdmins");

      v.getAllCabRequests();
    });
  },
  mounted() {},
  watch: {
    isCabAdmin(newValue, oldValue) {
      if (!newValue) {
        this.dialog = true;
        this.calcCountDown();
      }
    },
  },

  computed: {
    headers() {
      return [
        {
          text: "#No",
          align: "center",
          sortable: true,
          value: "no",
        },
        { text: "Requested At", align: "", value: "postingDate" },
        { text: "Requested By", align: "", value: "requestedBy" },
        { text: "Description", align: "", value: "description" },
        { text: "Amount", align: "center", value: "amountCharged" },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status",
        },
        {
          text: "Used status",
          align: "start",
          sortable: true,
          value: "used",
        },
        { text: "Action", align: "start", value: "actions" },
      ];
    },
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    employees() {
      return this.$store.getters["Setup/setupGetter"]("leaveEmployees");
    },
    cabRequestAdmins() {
      return this.$store.getters["littlecab/littleCabGetters"]("cabAdmins");
    },
    cabRequests() {
      return this.$store.getters["littlecab/littleCabGetters"](
        "allCabRequests"
      ).sort((a, b) => {
        return new Date(b.postingDate) - new Date(a.postingDate);
      });
    },
  },
  methods: {
    getDate(date) {
      return helper.getDateFormat(date);
    },
    getStatusColor(status) {
      return helper.getColor(status).color;
    },
    getAllCabRequests: function () {
      this.$store.dispatch("littlecab/getAllCabRequests");
    },
    calcCountDown() {
      const interval = setInterval(() => {
        this.countDown--;
        if (this.countDown === 0) {
          clearInterval(interval);
          this.$router.push("/");
        }
      }, 1000);
    },

    canMarkCabRequestUsed(cabRequest) {
      const { status, used } = cabRequest;
      return (
        (status === "Released" || status === "Accounted") &&
        !used &&
        this.getCabAdmin()
      );
    },
    // check if is cab admin
    getCabAdmin() {
      this.isCabAdmin = this.cabRequestAdmins.some(
        (cabAdmin) => cabAdmin.employeeCode === this.userData.employee.trim()
      );
      return this.isCabAdmin;
    },
    markUsedHandler(cabRequest) {
      //fn () update & mark cab Request used
      if (!cabRequest) {
        return;
      }
      cabRequest.used = true;
      this.$store.dispatch("littlecab/markCabRequestUsed", cabRequest);
    },
  },
};
</script>

<style scoped></style>
