import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"top-bar mb-2"},[_c('h3',{staticClass:"primary--text text-lg-h5 mr-2"},[_vm._v(" Cab Admins Approval Requests ")]),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCardText,[_c(VDataTable,{ref:"cabrequest_list",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.cabRequests,"sort-desc":[true, true],"search":_vm.search,"multi-sort":"","id":"scollStyle"},scopedSlots:_vm._u([{key:"item.postingDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.postingDate)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.getStatusColor(item.status)}},[_vm._v(_vm._s(item.status))])]}},{key:"item.used",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.used === true ? "Used" : "Not Used"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canMarkCabRequestUsed(item))?_c(VBtn,{staticClass:"primary lighten-4 primary--text text--darken-4",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.markUsedHandler(item)}}},[_c(VIcon,{staticClass:"mx-2"},[_vm._v("mdi-gesture-tap-button")]),_vm._v(" Mark used ")],1):_vm._e()]}}])})],1)],1),_c(VDialog,{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"width":"100%"}},[_c(VCardText,[_c('div',{staticClass:"text-body-1"},[_vm._v(" Sorry, you are not allowed to view this cab page. Please contact the system-admin/admin for more information. ")])]),_c(VCardActions,[_c('div',[_vm._v("Redirecting in "+_vm._s(_vm.countDown)+" seconds...")]),_c(VSpacer),_c(VBtn,{staticClass:"text-body-1 text-capitalize",attrs:{"color":"success","small":"","link":"","to":"/"}},[_c(VIcon,{staticClass:"mx-2"},[_vm._v("dashboard")]),_vm._v(" Go to Dashboard ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }