export default {
  computed: {
    dimension1() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 1 && item.dimensionValueType === "Standard"
      );
    },
    dimension2() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 2 && item.dimensionValueType === "Standard"
      );
    },
    dimension3() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 3 && item.dimensionValueType === "Standard"
      );
    },
    dimension4() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 4 && item.dimensionValueType === "Standard"
      );
    },
    dimension5() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 5 && item.dimensionValueType === "Standard"
      );
    },
    dimension6() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 6 && item.dimensionValueType === "Standard"
      );
    },
    dimension7() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 7 && item.dimensionValueType === "Standard"
      );
    },
    dimension8() {
      return this.dimensionValues.filter(
        (item) =>
          item.globalDimensionNo === 8 && item.dimensionValueType === "Standard"
      );
    },
    funds() {
      return this.$store.getters["littlecab/littleCabGetters"]("funds");
    },
    speedKeys() {
      return this.$store.getters["littlecab/littleCabGetters"]("speedKeys");
    },
  },
  methods: {
    cancelCabApprovalRequest(payload) {
      if (this.$route.query.code) {
        this.$store.dispatch("littlecab/sendCancelRequest", {
          documentNo: this.$route.query.code,
        });
      } else {
        this.$store.dispatch("littlecab/sendCancelRequest", {
          documentNo: payload.no,
        });
      }
    },
    deleteCabRequest: function (data) {
      this.$confirm.show({
        title: this.$route.params.documentType ? `Request No:  ${data.no}` : "",
        text: "Are you sure you want to delete this request?",
        onConfirm: () => {
          this.$store.dispatch("littlecab/deleteCabRequest", { data });
          // eslint-disable-next-line no-unused-expressions
          this.$route.query.code ? Event.$emit("back") : null;
        },
      });
    },
  },
};
